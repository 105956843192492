<template>
  <a-modal
    title="添加/编辑报修"
    :width="800"
    :destroyOnClose="true"
    :confirmLoading="addloading"
    :visible="visible"
    @ok="saveEventApi"
    @cancel="cancelForm"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="标题"
        :validateStatus="checkParams.titleStatus"
        :help="checkParams.titleStatusMsg"
      >
        <a-input
          placeholder="请输入标题"
          v-decorator="['title', {initialValue: eventDealData.title, rules: [{required: true, validator: titleCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        label="维修类型"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
      >
        <a-select
          v-model="eventDealData.maintainType"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option :key="0" :value="0">请选择</a-select-option>
          <a-select-option v-for="item in maintainTypeList" :key="item.itemId" :value="item.itemId">{{ item.itemName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="所属小区"
        :required="true"
      >
        <a-row :gutter="24">
          <a-col :md="12" :sm="8">
            <a-form-item
              :validateStatus="checkParams.eventCountyStatus"
              :help="checkParams.eventCountyStatusMsg"
            >
              <a-cascader
                style="width:248px;"
                :options="regionList"
                placeholder="省-市-区(县)"
                :field-names="{label: 'label', value: 'value', children: 'children' }"
                :value="defaultRegion"
                @change="changeRegion"
              />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8">
            <a-form-item
              :validateStatus="checkParams.communityIdStatus"
              :help="checkParams.communityIdStatusMsg"
            >
              <a-select
                style="width:238px;"
                v-model="eventDealData.communityId"
                placeholder="请选择小区"
                allow-clear
                @change="changeCommunity"
              >
                <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                <a-select-option v-for="item in regionCommunity" :key="item.keyId" :value="item.id">
                  {{ item.communityName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="上报人"
        :validateStatus="checkParams.reporterIdStatus"
        :help="checkParams.reporterIdStatusMsg"
        :required="true"
      >
        <a-select
          style="width: 250px;"
          :value="eventDealData.reporterId"
          allow-clear
          show-search
          placeholder="请输入姓名/手机号关键字、ID"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetchingUser ? undefined : null"
          :options="searchUserList"
          @search="handleUserSearch"
          @change="handleUserChange"
        >
          <template v-if="fetchingUser" #notFoundContent>
            <div style="text-align: center" v-if="isUserNull === false">
              <a-spin size="small" />
            </div>
            <div v-else>
              <div style="text-align: center">{{ notUserFoundContent }}</div>
            </div>
          </template>
        </a-select>
        <span style="margin-left: 20px; color: #ff2e33">*请先选择区域，小区，再搜索用户</span>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="地址"
        :validateStatus="checkParams.eventPlaceStatus"
        :help="checkParams.eventPlaceStatusMsg"
      >
        <a-input
          placeholder="请输入地址"
          v-decorator="['placeDetail', {initialValue: eventDealData.placeDetail, rules: [{required: true, validator: eventPlaceCheck, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="详情"
      >
        <a-textarea :rows="5" v-model="eventDealData.desc" placeholder="上报内容" />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px;"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="图片"
      >
        <a-upload
          :fileList="fileList"
          listType="picture-card"
          :disabled="isImageUpload"
          :beforeUpload="beforeUpload"
          :customRequest="customRequest"
          @preview="handlePreview"
          :remove="removeImage"
          accept="image/*"
        >
          <div>
            <span><a-icon :type="uploading ? 'loading' : 'picture'" /></span>
            <span>上传</span>
          </div>
        </a-upload>
        <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
        </a-modal>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import OSS from 'ali-oss'
import { addImagelibrary, getToken } from '@/api/upload'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import { maintainCondition, saveMaintainReport } from '@/api/maintain'
import { searchMember } from '@/api/user'

export default {
  name: 'MaintainInfo',
  props: {
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addEventForm' }),
      visible: false,
      addloading: false,
      dealCheckStatus: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      queryParam: {
        id: '',
        keyId: 0,
        status: -1,
        desc: '',
        imageList: []
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      fileList: [],
      isImageUpload: false,
      uploading: false,
      uploadImageInfo: {
        fileName: '',
        url: '',
        width: 0,
        height: 0,
        size: 0,
        bucket: 'dadaojianqi-syue',
        type: 0,
        transcoding: 0
      },
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      },
      checkParams: {
        statusStatus: 'success',
        statusStatusMsg: null,
        titleStatus: 'success',
        titleStatusMsg: null,
        communityIdStatus: 'success',
        communityIdStatusMsg: null,
        eventCountyStatus: 'success',
        eventCountyStatusMsg: null,
        eventPlaceStatus: 'success',
        eventPlaceStatusMsg: null,
        reporterIdStatus: 'success',
        reporterIdStatusMsg: null
      },
      eventDealData: {},
      statusList: [],
      maintainTypeList: [],
      eventLevelList: [],
      eventSourceList: [],
      eventSourceList1: [],
      eventSourceList2: [],
      eventTypeList: [],
      regionList: [],
      defaultRegion: [],
      regionCommunity: [],
      eventGroup: [],
      searchUserList: [],
      searchUserTitle: '',
      timeoutUser: null,
      fetchingUser: false,
      isUserNull: false,
      notUserFoundContent: '未搜到用户',
      userData: []
    }
  },
  created () {
  },
  methods: {
    initialData (data) {
      this.eventDealData = Object.assign({}, data)
      console.log('eventDealData', this.eventDealData)
      this.getTokenApi()
      this.regionListApi()
      this.maintainConditionApi()
      if (this.eventDealData.reportCounty !== '' && this.eventDealData.reportCounty !== 0) {
        this.defaultRegion = [this.eventDealData.reportProvince, this.eventDealData.reportCity, this.eventDealData.reportCounty]
        this.regionCommunityApi(this.eventDealData.reportCounty)
      } else {
        this.defaultRegion = []
      }

      if (this.eventDealData.keyId > 0) {
        this.searchUserTitle = this.eventDealData.reporterKeyId
        this.searchAllUser(1)
      } else {
        this.searchUserTitle = ''
        this.eventDealData.reporterId = undefined
        this.searchUserList = []
        this.userData = []
        this.fetchingUser = false
        this.isUserNull = false
      }

      if (this.eventDealData.maintainType === '') {
        this.eventDealData.maintainType = 0
      }
      this.fileList = this.eventDealData.attachmentsList
      console.log(this.fileList)
      this.visible = true
    },
    closeSeeInfo () {
      this.visible = false
    },
    cancelForm () {
      this.visible = false
    },
    handleCancel (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    saveEventApi () {
      const _this = this
      console.log('saveDealEvent', _this.eventDealData)
      if (_this.eventDealData.title.trim() === '') {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入事件标题'
        return false
      } else {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      }

      if (_this.eventDealData.reportCounty === 0 || _this.eventDealData.reportCounty === '') {
        _this.checkParams.eventCountyStatus = 'error'
        _this.checkParams.eventCountyStatusMsg = '请选择区域'
        return false
      } else {
        _this.checkParams.eventCountyStatus = 'success'
        _this.checkParams.eventCountyStatusMsg = null
      }

      if (_this.eventDealData.communityId === 0 || _this.eventDealData.communityId === undefined) {
        _this.checkParams.communityIdStatus = 'error'
        _this.checkParams.communityIdStatusMsg = '请选择小区'
        return false
      } else {
        _this.checkParams.communityIdStatus = 'success'
        _this.checkParams.communityIdStatusMsg = null
      }

      if (this.eventDealData.reporterId === '' || this.eventDealData.reporterId === undefined) {
        this.checkParams.reporterIdStatus = 'error'
        this.checkParams.reporterIdStatusMsg = '请选择上报人'
        return false
      } else {
        this.checkParams.reporterIdStatus = 'success'
        this.checkParams.reporterIdStatusMsg = null
      }

      if (_this.eventDealData.placeDetail.trim() !== '') {
        _this.checkParams.eventPlaceStatus = 'success'
        _this.checkParams.eventPlaceStatusMsg = null
      } else {
        _this.checkParams.eventPlaceStatus = 'error'
        _this.checkParams.eventPlaceStatusMsg = '请输入详情地址'
        return false
      }
      _this.addloading = true
      saveMaintainReport(_this.eventDealData).then((res) => {
        if (res.errorCode === 0) {
          if (_this.eventDealData.keyId > 0) {
            _this.$message.success('编辑成功')
          } else {
            _this.$message.success('添加成功')
          }
          _this.addloading = false
          this.$emit('ok', 1)
          this.visible = false
        } else {
          _this.addloading = false
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.addloading = false
        console.log(err)
      })
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e.url
      this.previewInfo.previewTitle = e.name
      this.previewInfo.previewVisible = true
      console.log('handlePreview', e)
    },
    removeImage (e) {
      console.log('removeImage', e)
      this.fileList.forEach((item, index) => {
        if (item.uid === e.uid) {
          this.fileList.splice(index, 1)
        }
      })

      var indexa = this.eventDealData.attachments.indexOf(e.url)
      if (indexa > -1) {
        this.eventDealData.attachments.splice(indexa, 1)
      }
      return true
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    addImagelibraryApi () {
      const _this = this
      addImagelibrary(_this.uploadImageInfo).then((res) => {
        if (res.errorCode === 0) {
          const imgRow = {
            uid: res.result.id,
            name: res.result.fileName,
            status: 'done',
            url: res.result.url
          }
          _this.fileList.push(imgRow)
          _this.eventDealData.attachments.push(res.result.url)
          console.log('fileList', _this.fileList)
          _this.$message.success('上传成功')
          console.log('addImagelibrary', res.result)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.isImageUpload = false
        _this.uploading = false
      }).catch((err) => {
        _this.isImageUpload = false
        _this.uploading = false
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    async customRequest (e) {
      console.log('customRequest', e)
      const _this = this
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': _this.uploadImageInfo.fileName,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(_this.uploadImageInfo.fileName, e.file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          _this.uploadImageInfo.url = _this.tokenInfo.domain + result.name
          _this.addImagelibraryApi()
        } else {
          _this.$message.error('上传失败')
          _this.isImageUpload = false
          _this.uploading = false
        }
        console.log(result)
      } catch (e) {
        _this.isImageUpload = false
        _this.uploading = false
        console.log(e)
      }
    },
    beforeUpload (file, fileList) {
      console.log('beforeUpload', file)
      if (this.isImageUpload) {
        this.$message.error('有其他图片正常上传，请稍后在操作')
        return false
      }
      console.log(file)
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      console.log(keyimg)
      this.uploadImageInfo.fileName = keyimg
      this.uploadImageInfo.size = Math.ceil(file.size / 1000)
      this.isImageUpload = true
      this.uploading = true
      return true
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    },
    changeCommunity (e) {
      console.log('changeCommunity', e)
      if (e === 0 || e === undefined) {
        this.checkParams.communityIdStatus = 'error'
        this.checkParams.communityIdStatusMsg = '请选择小区'
      } else {
        this.checkParams.communityIdStatus = 'success'
        this.checkParams.communityIdStatusMsg = null
      }
    },
    titleCheck (rule, value, callback) {
      const _this = this
      _this.eventDealData.title = value.trim()
      if (_this.eventDealData.title !== '') {
        _this.checkParams.titleStatus = 'success'
        _this.checkParams.titleStatusMsg = null
      } else {
        _this.checkParams.titleStatus = 'error'
        _this.checkParams.titleStatusMsg = '请输入事件标题'
        callback(new Error('请输入事件标题'))
      }
    },
    eventPlaceCheck (rule, value, callback) {
      const _this = this
      _this.eventDealData.placeDetail = value.trim()
      if (_this.eventDealData.placeDetail !== '') {
        _this.checkParams.eventPlaceStatus = 'success'
        _this.checkParams.eventPlaceStatusMsg = null
      } else {
        _this.checkParams.eventPlaceStatus = 'error'
        _this.checkParams.eventPlaceStatusMsg = '请输入详情地址'
        callback(new Error('请输入详情地址'))
      }
    },
    maintainConditionApi () {
      const _this = this
      maintainCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.statusList = res.result.statusList
          _this.maintainTypeList = res.result.maintainTypeList
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.eventDealData.reportProvince = e[0] ? e[0] : 0
      this.eventDealData.reportCity = e[1] ? e[1] : 0
      this.eventDealData.reportCounty = e[2] ? e[2] : 0
      this.regionCommunityApi(this.eventDealData.reportCounty)
      this.eventDealData.communityId = 0
      if (this.eventDealData.reportCounty === 0) {
        this.checkParams.eventCountyStatus = 'error'
        this.checkParams.eventCountyStatusMsg = '请选择区域'
      } else {
        this.checkParams.eventCountyStatus = 'success'
        this.checkParams.eventCountyStatusMsg = null
      }
    },
    regionCommunityApi (areaCode) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.regionCommunity = res.result.data
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleUserSearch (e) {
      const _this = this
      _this.searchUserTitle = e.trim()
      if (_this.timeoutUser) {
        clearTimeout(_this.timeoutUser)
        _this.timeoutUser = null
      }
      _this.timeoutUser = setTimeout(function () {
        _this.searchAllUser()
      }, 500)
    },
    searchAllUser (isId = 0) {
      const _this = this
      if (_this.searchUserTitle === '') {
        _this.searchUserList = []
        _this.userData = []
        _this.fetchingUser = false
        _this.isUserNull = false
        return false
      }

      _this.fetchingUser = true
      _this.isUserNull = false
      var searchParams = {
        title: _this.searchUserTitle,
        areaCode: 0,
        communityId: 0
      }
      if (isId === 0) {
        searchParams.areaCode = _this.eventDealData.reportCounty
        searchParams.communityId = _this.eventDealData.communityId
      }
      searchMember(searchParams)
        .then((res) => {
          console.log('searchCourseApi', res)
          _this.searchUserList = res.result.selectList
          _this.userData = res.result.userData
          if (_this.searchUserList.length > 0) {
            _this.fetchingUser = false
            _this.isUserNull = false
          } else {
            _this.fetchingUser = true
            _this.isUserNull = true
          }
        })
        .catch((err) => {
          console.log(err)
          _this.fetchingUser = false
          _this.isUserNull = false
        })
    },
    handleUserChange (e) {
      console.log('handleUserChange', e)
      if (e === '' || e === undefined) {
        this.eventDealData.reporterId = undefined
        this.searchUserList = []
        this.checkParams.reporterIdStatus = 'error'
        this.checkParams.reporterIdStatusMsg = '请选择上报人'
      } else {
        this.eventDealData.reporterId = e
        this.checkParams.reporterIdStatus = 'success'
        this.checkParams.reporterIdStatusMsg = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
