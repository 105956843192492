import request from '@/utils/request'
const baseURL = '/baseapi'
const MaintainApi = {
  MaintainList: '/admin/maintain/list',
  MaintainReportInfo: '/admin/maintain/maintainReportInfo',
  SaveMaintainReport: '/admin/maintain/saveMaintainReport',
  SetMaintainStatus: '/admin/maintain/setStatus',
  DelMaintain: '/admin/maintain/delMaintain',
  Condition: '/admin/maintain/condition'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function maintainList (parameter) {
  return request({
    baseURL: baseURL,
    url: MaintainApi.MaintainList,
    method: 'post',
    data: parameter
  })
}

export function maintainReportInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: MaintainApi.MaintainReportInfo,
    method: 'post',
    data: parameter
  })
}

export function saveMaintainReport (parameter) {
  return request({
    baseURL: baseURL,
    url: MaintainApi.SaveMaintainReport,
    method: 'post',
    data: parameter
  })
}

export function setMaintainStatus (parameter) {
  return request({
    baseURL: baseURL,
    url: MaintainApi.SetMaintainStatus,
    method: 'post',
    data: parameter
  })
}

export function delMaintain (parameter) {
  return request({
    baseURL: baseURL,
    url: MaintainApi.DelMaintain,
    method: 'post',
    data: parameter
  })
}

export function maintainCondition () {
  return request({
    baseURL: baseURL,
    url: MaintainApi.Condition,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
